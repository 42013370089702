import React, { useState, useEffect, useRef } from "react"
import {
  TextField,
  Paper,
  Snackbar,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  CircularProgress,
} from "@material-ui/core"
import { useStyles } from "../common/articleBenderStyles"
import "./styles.css"
import AuthorizedPageWrapper from "../context/AuthorizedPageWrapper"
import ChatMessage from "../components/ChatMessageClient"
import { URL_BASE } from "../utils/consts"
import Alert from "@material-ui/lab/Alert/Alert"
import { callAnalyticsEvent } from "../utils"
import { NavbarLayout } from "../layouts/AppLayout"
import SendIcon from "@material-ui/icons/Send"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import FeedbackComponent from "../components/FeedbackComponent"
import useChat from "../hooks/useChat"
import useGetUserConversations from "../hooks/conversations/useGetUserConversations"
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import { CHAT_NAME } from "../constants/products"
import { FaBrain, FaPen } from "react-icons/fa6"
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core"
import useGetMindProducts from "../hooks/mind/useGetMindProducts"
const ArticleBender = () => {
  const [openChat, setOpenChat] = useState(false)
  return (
    <NavbarLayout
      setOpenChat={setOpenChat}
      openChat={openChat}
      product={CHAT_NAME}
    >
      <AuthorizedPageWrapper>
        <App />
      </AuthorizedPageWrapper>
    </NavbarLayout>
  )
}

const App = () => {
  const classes = useStyles()
  const paperRef = useRef()
  const endRef = useRef()
  const [selectedMind, setSelectedMind] = useState("")
  const [showClipboardNotification, setShowClipboardNotification] =
    useState(false)
  const [isFeedbackModalOpen, setShowFeedbackModal] = useState(false)
  const [feedbackId, setFeedbackId] = useState(null)
  const {
    data: userConversations,
    refetch: refetchConversations,
    loading: loadingConversations,
  } = useGetUserConversations()

  const {
    input,
    setInput,
    loading,
    error,
    setError,
    conversation,
    handleSubmit,
    isWriting,
    errorMsg,
    handleMemoryUpload,
  } = useChat(
    endRef,
    "/gpt/enterprise-assistant",
    refetchConversations,
    selectedMind
  )

  const { data: minds } = useGetMindProducts("EnterpriseGPT")

  useEffect(() => {
    if (minds && minds.length > 0) {
      setSelectedMind(minds[0]._id)
    }
  }, [minds])

  const handleInputChange = (event) => {
    setInput(event.target.value)
  }

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setError(false)
    setShowClipboardNotification(false)
  }

  useEffect(() => {
    if (endRef.current) {
      endRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
    }
  }, [conversation.memory])

  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false)
  }

  const handleOpenPositiveFeedbackModal = () => {
    setShowFeedbackModal(true)
    if (!feedbackId) {
      createFeedback(true)
    }
  }

  const handleOpenNegativeFeedbackModal = () => {
    setShowFeedbackModal(true)
    if (!feedbackId) {
      createFeedback(false)
    }
  }

  const handleSendFeedback = (feedbackText) => {
    updateFeedback(feedbackText)
    handleCloseFeedbackModal(false)
  }

  const createFeedback = async (isPositive) => {
    try {
      const token = localStorage.getItem("token")
      const response = await fetch(URL_BASE + "/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          isPositive,
          conversationId: conversation._id,
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.error)
      }

      setFeedbackId(data.feedback._id)
    } catch (error) {
      setError(error.message)
    }
  }
  const handleMindChange = (event) => {
    setSelectedMind(event.target.value)
  }
  const updateFeedback = async (feedbackText) => {
    if (!feedbackId || !feedbackText) return

    try {
      const token = localStorage.getItem("token")
      const response = await fetch(URL_BASE + `/feedback/${feedbackId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ feedbackText }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.error)
      }
    } catch (error) {
      setError(error.message)
    }
  }

  const handleSelectConversation = async (conversation) => {
    await refetchConversations()
    handleMemoryUpload(conversation)
  }

  return (
    <Container maxWidth="xl">
      <Grid container style={{ marginTop: 36 }}>
        <Grid item xs={2}>
          <Card
            style={{
              width: 250,
              marginTop: 20,
              height: 750,
              overflowY: "auto",
            }}
          >
            <CardContent style={{ padding: 0 }}>
              <Grid container justify="space-between">
                <Typography style={{ fontWeight: 700, padding: 12 }}>
                  Historial
                </Typography>
                <Button>
                  <FaPen />
                </Button>
              </Grid>
              <Divider />
              {loadingConversations ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  style={{ marginTop: 250 }}
                >
                  <CircularProgress />
                  <Typography style={{ fontSize: 12, marginTop: 24 }}>
                    Cargando conversaciones...
                  </Typography>
                </Grid>
              ) : (
                <List component="nav" aria-label="mailbox folders">
                  {userConversations?.map((conv) => (
                    <ListItem
                      button
                      onClick={() => handleSelectConversation(conv)}
                      selected={conv._id === conversation?._id}
                    >
                      <ListItemText
                        primary={conv?.title || "Conversación sin título"}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth>
            <InputLabel id="mind-select-label">
              <FaBrain /> Selecciona un agente
            </InputLabel>
            <Select
              labelId="mind-select-label"
              value={selectedMind}
              onChange={handleMindChange}
            >
              {minds?.map((mind) => (
                <MenuItem key={mind._id} value={mind._id}>
                  {mind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper ref={paperRef} elevation={1} className={classes.paper}>
            <Alert severity="warning">
              ¡Importante! Esta aplicación se encuentra en desarrollo.
            </Alert>
            {conversation?.memory?.map(
              ({ text, source, senderType, systemMessage, error }, index) => (
                <Grid container key={text}>
                  <ChatMessage
                    message={text}
                    paperRef={endRef}
                    sender={source === "ai" ? "Asistente" : "Tú"}
                    senderType={senderType === "loading" ? senderType : source}
                    systemMessage={systemMessage}
                    isInitialMessage={index === 0}
                    isWritingMessage={isWriting}
                    setShowClipboardNotification={setShowClipboardNotification}
                    handleOpenPositiveFeedbackModal={
                      handleOpenPositiveFeedbackModal
                    }
                    handleOpenNegativeFeedbackModal={
                      handleOpenNegativeFeedbackModal
                    }
                    error={error}
                  />
                </Grid>
              )
            )}

            <div ref={endRef} />
          </Paper>
          <Grid item xs={12}>
            <Paper
              style={{ padding: 24, marginTop: 12, background: "#fafcff" }}
            >
              <TextField
                style={{ background: "#fff" }}
                fullWidth
                label={"Escribe tu consulta"}
                variant="outlined"
                value={input}
                onChange={handleInputChange}
                multiline
                rows={3}
                disabled={loading || isWriting}
                className={classes.textField}
                InputProps={{
                  onKeyDown: (event) => {
                    if (event.key === "Enter" && !event.shiftKey) {
                      event.preventDefault() // To prevent a new line from being added

                      handleSubmit()
                      callAnalyticsEvent("chat.submit")
                    }
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          handleSubmit()
                          callAnalyticsEvent("chat.submit")
                        }}
                        disabled={loading || isWriting}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
            <Grid
              container
              justify="center"
              style={{ padding: 6, textAlign: "center" }}
            >
              <Typography
                variant="caption"
                style={{
                  fontSize: 11,
                  color: "#625c5c",
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                Esta aplicación está en desarrollo.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={showClipboardNotification}
        autoHideDuration={2000}
        onClose={handleCloseNotification}
      >
        <Alert> Mensaje copiado al cortapapeles.</Alert>
      </Snackbar>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
      <FeedbackComponent
        open={isFeedbackModalOpen}
        handleClose={handleCloseFeedbackModal}
        handleSendFeedback={handleSendFeedback}
      />
    </Container>
  )
}

export default ArticleBender
