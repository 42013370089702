import { useEffect, useState } from "react"
import { URL_BASE } from "../../utils/consts"

export default () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const fetchConversations = async () => {
    const token = localStorage.getItem("token")
    try {
      const response = await fetch(
        URL_BASE + "/gpt/enterprise-assistant/user/conversations",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = await response.json()
      setData(data?.conversations)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const refetch = fetchConversations

  useEffect(() => {
    fetchConversations()
  }, [])

  return { data, error, loading, refetch }
}
